.d14rchyi{cursor:not-allowed;}
.nl5bmh4{cursor:pointer;}
.r1k16bc6{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;color:#fff;line-height:1;border-radius:54px;border:0;text-align:center;-webkit-transition:all 0.1s ease-out;transition:all 0.1s ease-out;-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0);-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;font-weight:var(--font-weight-desktop-lg);box-sizing:border-box;}.r1k16bc6:focus,.r1k16bc6:active,.r1k16bc6:focus-visible{border:0;outline:0;}.r1k16bc6:not(.d14rchyi):active{-webkit-transform:translateY(2px);-ms-transform:translateY(2px);transform:translateY(2px);}.r1k16bc6 i{vertical-align:middle;}
.xmj6olu{min-height:32px;padding:0 var(--element-padding-md);font-size:var(--font-size-xxs);}.xmj6olu svg{width:16px;height:16px;margin-left:-2px;}
.s2r4pwv{padding:0 var(--element-spacing-md);font-size:var(--font-size-xxs);min-height:40px;}.s2r4pwv svg{width:20px;height:20px;margin-left:-2px;}
.msm51bu{font-size:var(--font-size-xs);padding:0 var(--element-spacing);min-height:48px;}.msm51bu svg{width:24px;height:24px;margin-left:-2px;}
.l1ukzcpo{padding:0 var(--element-padding);min-height:56px;font-size:var(--font-size-sm);}.l1ukzcpo svg{width:28px;height:28px;margin-left:-2px;}
.x1jmpqzm{padding:0 var(--element-padding-lg);min-height:64px;font-size:var(--font-size-md);font-weight:var(--font-weight-lg);}.x1jmpqzm svg{width:32px;height:32px;margin-left:-2px;}
.pdta3yz{background-color:var(--gsu-primary);}.pdta3yz.d14rchyi{background-color:var(--gsu-primary20);}.pdta3yz:not(.d14rchyi):hover{background-color:var(--gsu-primary-dark);}
.fz3lqkv{background-color:#1877f2;}.fz3lqkv.d14rchyi{background-color:var(--gsu-primary20);}.fz3lqkv:not(.d14rchyi):hover{background-color:var(--gsu-primary-dark);}
.sjc4li4{background-color:var(--gsu-secondary);}.sjc4li4.d14rchyi{opacity:0.7;color:var(--gsu-secondary60);}.sjc4li4:not(.d14rchyi):hover{background-color:var(--gsu-secondary-dark);}
.t1cxxmz0{background-color:var(--gsu-tertiary10);color:#000;}.t1cxxmz0.d14rchyi{opacity:0.7;}.t1cxxmz0:not(.d14rchyi):hover{background-color:var(--gsu-tertiary40);}
.p1bfa37s{background-color:var(--gsu-primary20);color:var(--gsu-secondary);}.p1bfa37s.d14rchyi{opacity:0.7;}.p1bfa37s:not(.d14rchyi):hover{background-color:var(--gsu-primary30);}
.w9u350s{background-color:#fff;color:var(--gsu-primary);}.w9u350s.d14rchyi{background-color:#f1f1f1;}.w9u350s:hover{background-color:var(--gsu-grey10);}
.g1npqfl8{background-color:rgba(255,255,255,0.1);}.g1npqfl8:not(.d14rchyi):hover{background-color:rgba(255,255,255,0.2);}
.s1nlycv6{background-color:rgba(0,0,0,0.6);}.s1nlycv6:not(.d14rchyi):hover{background-color:rgba(0,0,0,0.75);}
.g1v5ke8y{background-color:var(--gsu-green);}.g1v5ke8y.d14rchyi{opacity:0.7;color:var(--gsu-white);}.g1v5ke8y:not(.d14rchyi):hover{background-color:var(--gsu-green);}
.crfiisc{background-color:transparent;-webkit-text-decoration:underline;text-decoration:underline;color:var(--gsu-primary);}.crfiisc.d14rchyi{color:var(--gsu-primary30);}.crfiisc:not(.d14rchyi):hover{color:var(--gsu-primary-dark);background:var(--gsu-grey20);}
.l1d0f7wg{background-color:transparent;-webkit-text-decoration:underline;text-decoration:underline;color:var(--gsu-primary);}.l1d0f7wg.d14rchyi{color:var(--gsu-primary30);}
.n1wl6tbu{background-color:transparent;-webkit-text-decoration:none;text-decoration:none;color:var(--gsu-primary);}.n1wl6tbu.d14rchyi{color:var(--gsu-primary30);}
.a1cgfs37{background-color:var(--gsu-grey10);color:var(--gsu-dark-grey);}.a1cgfs37.d14rchyi{opacity:0.6;}.a1cgfs37:not(.d14rchyi):hover{background-color:var(--gsu-grey20);}
.p1yhwr1d{background:transparent;color:var(--gsu-primary);border:1px solid var(--gsu-primary);}
.pj3oksm{background:transparent;color:var(--gsu-primary);border:1px solid var(--gsu-primary);-webkit-transition:0.5s;transition:0.5s;}.pj3oksm:hover{color:#fff;background:var(--gsu-primary);}
.s1lcp1jc{background:transparent;color:var(--gsu-secondary);border:1px solid var(--gsu-secondary);}
.wk67liw{background:transparent;color:#fff;border:1px solid #fff;}
.s1pddbbv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;}
.ifx4mje{border-radius:50%;padding:0;min-height:auto;}.ifx4mje i{margin:0 !important;padding:0 !important;height:50% !important;width:50% !important;}.ifx4mje.xmj6olu{height:32px;width:32px;}.ifx4mje.s2r4pwv{height:40px;width:40px;}.ifx4mje.msm51bu{height:48px;width:48px;}.ifx4mje.l1ukzcpo{height:56px;width:56px;}.ifx4mje.x1jmpqzm{height:64px;width:64px;}
.s1cd4l3n{border-radius:0;}
.t1gxvk1t{padding:0 !important;min-height:unset !important;-webkit-text-decoration:none !important;text-decoration:none !important;color:var(--gsu-secondary) !important;}.t1gxvk1t:hover{padding:0 !important;min-height:unset !important;-webkit-text-decoration:none !important;text-decoration:none !important;color:var(--gsu-secondary) !important;background:transparent !important;}
.t1qa63kp{padding:0 !important;min-height:unset !important;-webkit-text-decoration:none !important;text-decoration:none !important;color:var(--gsu-primary) !important;}.t1qa63kp:hover{padding:0 !important;min-height:unset !important;-webkit-text-decoration:none !important;text-decoration:none !important;color:var(--gsu-primary) !important;background:transparent !important;}
